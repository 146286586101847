module.exports = [{
      plugin: require('/Users/wilhelmeklund/repos/wilhelmeklund.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/wilhelmeklund/repos/wilhelmeklund.com/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1100,"quality":90,"linkImagesToOriginal":true},
    },{
      plugin: require('/Users/wilhelmeklund/repos/wilhelmeklund.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-19663486-1"},
    },{
      plugin: require('/Users/wilhelmeklund/repos/wilhelmeklund.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
